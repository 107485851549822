///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	#footer {
		@include vendor('transition', (
			'transform #{_duration(article)} ease-in-out',
			'filter #{_duration(article)} ease-in-out',
			'opacity #{_duration(article)} ease-in-out',
		));
		max-width: 100%;
		margin-top: 2rem;
		text-align: center;

		.copyright {
			letter-spacing: _font(letter-spacing);
			font-size: 0.6rem;
			opacity: 0.75;
			margin-bottom: 0;
			text-transform: uppercase;
		}

		.body.is-article-visible & {
			@include vendor('transform', 'scale(0.95)');
			@include vendor('filter', 'blur(0.1rem)');
			opacity: 0;
		}

		.body.is-loading & {
			opacity: 0;
		}
	}