///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Box */

	.box {
		@include vendor('transition', (
			'transform #{_duration(article)} ease-in-out',
			'filter #{_duration(article)} ease-in-out',
			'opacity #{_duration(article)} ease-in-out',
		));
		border-radius: _size(border-radius);
		border: solid _size(border-width) _palette(border);
		margin-top: _size(element-margin);
		margin-bottom: _size(element-margin);
		padding: 2em;
		max-width: 70%;
		min-width: 50%;

		> :last-child,
		> :last-child > :last-child,
		> :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		&.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}


	}
	.gen-box {
		@include vendor('transition', (
			'transform #{_duration(article)} ease-in-out',
			'filter #{_duration(article)} ease-in-out',
			'opacity #{_duration(article)} ease-in-out',
		));
		border-radius: _size(border-radius);
		border: solid _size(border-width) _palette(border);
		margin-top: _size(element-margin);
		margin-bottom: _size(element-margin);
		padding: 2em;
		max-width: 80%;
		min-width: 80%;

		font-family: 'Inconsolata', monospace;
		font-weight: 1em;

		> :last-child,
		> :last-child > :last-child,
		> :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		&.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}


	}